.news-page {
  &__articles {
    margin-bottom: 100px;
  }

  &__more {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__navigation {
    margin-bottom: 100px;
  }
}

.cards {
  &__row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 100px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--text-color);

    &:hover {
      color: var(--text-color-hover);
    }
  }

  &__prev {
    margin-right: 30px;
    transform: rotate(180deg);
  }

  &__next {
    margin-left: 30px;
  }

  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__page {
    margin-right: 30px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: var(--text-color-hover);
    }

    &_active {
      color: var(--text-color-hover);
    }
  }

  &__divider {
    margin-right: 30px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: var(--text-color);
    display: flex;
  }
}

.article-page {
  &__return {
    margin-top: 50px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--link-color);

    span {
      transform: rotate(180deg);
      margin-right: 30px;
      font-size: 1.5rem;
    }

    &:hover {
      color: var(--link-color-hover);
    }
  }

  &__desc {
    margin-left: 250px;
  }

  &__title {
    padding: 50px 0;
  }

  &__date {
    margin-bottom: 50px;
    font-size: 1rem;
    font-weight: 300;
    color: var(--text-color);
  }

  &__main-img {
    margin-bottom: 100px;

    img {
      width: 100%;
    }
  }

  &__btn-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 150px 100px 150px;
  }

  &__next {
    margin-left: auto;
  }
}

.article {
  p {
    font-size: 1rem;
    color: var(--text-color);
    line-height: 140%;
    padding-bottom: 20px;
    max-width: 870px;

    &:last-child {
      padding-bottom: 100px;
    }

    a {
      display: inline !important;
      margin: 0 !important;
    }
  }

  a {
    display: inline-block;
    font-size: 1rem;
    color: var(--text-color-hover);
    line-height: 140%;
    padding-bottom: 20px;
    max-width: 870px;

    &:last-child {
      padding-bottom: 100px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  strong, b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  img, picture {
    display: block;
    width: 870px;
    margin-bottom: 100px;
    margin-top: 30px;
  }

  img.full {
    width: calc(100% + 250px);
    margin-left: -250px;
    max-width: none !important;
  }

  blockqoute {
    display: block;
    padding: 20px;
    background-color: var(--bg-light);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul, ol {
    padding-left: 18px;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 100px;
    }

    a {
      display: inline !important;
      margin: 0 !important;
    }

    li {
      font-size: 1rem;
      color: var(--text-color);
      line-height: 140%;
      max-width: 870px;
    }
  }
}

@media (max-width: 1792.99px) {
  .cards {
    &__row {
      column-gap: 80px;
    }
  }
}

@media (max-width: 1459.99px) {
  .cards {
    &__row {
      column-gap: 10px;
    }
  }
}

@media (max-width: 1319.99px) {
  .cards {
    &__row {
      column-gap: 30px;
    }
  }

  .article {
    margin-left: 0;
  }

  .article-page {
    &__btn-group {
      margin: 0;
      margin-bottom: 100px;
    }
  }
}

@media (max-width: 999.99px) {
  .cards {
    &__row {
      column-gap: 20px;
    }
  }

  .news-page {
    &__navigation {
      justify-content: center;
    }
  }
}

@media (max-width: 639.99px) {
  .pagination {
    &__page,
    &__divider,
    &__prev {
      margin-right: 15px;
    }
    &__next {
      margin-left: 15px;
    }
  }

  .article-page {
    &__title {
      padding-bottom: 25px;
    }

    &__date {
      margin-bottom: 25px;
    }

    &__main-img {
      margin-bottom: 50px;
    }

    &__prev,
    &__next {
      padding: 22px 9px;
      width: auto;
    }
  }

  .article {
    img, picture {
      margin-bottom: 50px;
    }

    p, a, ul, ol {
      &:last-child {
        padding-bottom: 50px;
      }
    }
  }
}
