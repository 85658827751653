.directions-page {
  &__title {
    margin: 50px 0;
  }

  &__banner {
    margin-bottom: 100px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
  }
}

.directions-menu {
  margin-bottom: 50px;
  width: 420px;
  &__item {
    margin-bottom: 30px;
  }

  &__link {
    font-weight: 500;
    color: var(--link-color);
    position: relative;
    display: inline;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid var(--link-color);
    }

    &_active {
      border-bottom: 1px solid var(--link-color);
    }
  }
}

.directions-content {
  width: 1320px;
}

.directions-page-card {
  margin-bottom: 100px;

  &__banner {
    display: flex;
    margin-bottom: 70px;
  }

  &__info {
    width: 900px;
    padding: 30px;
    background-color: var(--bg-light);
  }

  &__title {
    margin-bottom: 10px;
  }

  &__code {
    display: inline-block;
    margin-bottom: 25px;
  }

  &__row {
    display: flex;
    margin-bottom: 70px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__group {
    width: 420px;

    h4 {
      margin-bottom: 25px;
    }
  }

  &__text {
    margin-bottom: 10px;

    span {
      font-weight: 500;
    }
  }

  &__nums {
    display: flex;
    width: 420px;
  }

  &__free,
  &__cost-full-time {
    margin-right: 65px;
  }

  &__count {
    margin-bottom: 20px;
  }

  &__img {
    width: 420px;
  }

  &__desc {
    width: 870px;
    margin-bottom: 50px;

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__cols {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 50px;
  }

  &__education,
  &__skills,
  &__subjects,
  &__projects,
  &__work,
  &__professions {
    width: 420px;

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    margin-bottom: 25px;
  }
}

@media (max-width: 1792.99px) {
  .directions-content {
    width: 1000px;
  }

  .directions-page-card {
    &__img {
      display: none;
    }

    &__info {
      width: 100%;
    }
  }
}

@media (max-width: 1459.99px) {
  .directions-nav {
    width: 410px;
  }
  .directions-content {
    width: 870px;
  }

  .directions-page-card {
    &__nums {
      width: auto;

      &:first-child {
        margin-right: 50px;
      }
    }
    &__free,
    &__cost-full-time {
      margin-right: 50px;
    }
  }
}

@media (max-width: 1319.99px) {
  .directions-page {
    &__group {
      flex-wrap: wrap;
      width: 100%;
    }

    &__banner {
      margin-bottom: 50px;
    }
  }

  .directions-nav {
    width: 100%;
    margin-bottom: 50px;
  }

  .directions-content {
    width: 100%;
  }

  .directions-menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 100px;
    row-gap: 20px;

    &__item {
      width: 345px;
      margin-bottom: 0;
    }

    &__link {
      &_active {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

@media (max-width: 999.99px) {
  .directions-menu {
    column-gap: 20px;

    &__item {
      width: 300px;
    }
  }

  .directions-page-card {
    &__info {
      padding: 30px 10px;
    }
  }

  .directions-page-card {
    &__row {
      margin-bottom: 50px;
    }

    &__count {
      margin-bottom: 10px;
    }

    &__nums {
      &:first-child {
        margin-right: 30px;
      }
    }

    &__free,
    &__cost-full-time {
      margin-right: 30px;
    }

    &__desc {
      width: 100%;
    }

    &__cols {
      column-gap: 20px;
    }

    &__education,
    &__skills,
    &__subjects,
    &__projects,
    &__work,
    &__professions {
      width: 300px;
    }
  }
}

@media (max-width: 639.99px) {
  .directions-page-card {
    &__row {
      flex-wrap: wrap;
    }

    &__group {
      width: 100%;

      &:first-child {
        margin-bottom: 30px;
      }
    }

    &__nums {
      &:first-child {
        margin-right: 0px;
        margin-bottom: 30px;
      }
    }

    &__free {
      margin-right: 46px;
    }

    &__cost-full-time {
      margin-right: 10px;
    }
  }
}
