.page {
  margin-bottom: 100px;

  &__title {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  &__subtitle {
    margin-top: 100px;
    margin-bottom: 25px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_uppercase {
      text-transform: uppercase;
    }
  }

  &__text {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text-warn {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtext {
    margin-bottom: 100px;
  }

  &__list {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      padding-left: 25px;
      position: relative;
      line-height: 140%;

      &::before {
        content: "";
        position: absolute;
        top: 9.5px;
        left: 11px;
        width: 3px;
        height: 3px;
        background-color: var(--text-color);
        border-radius: 50%;
      }
    }

    &_danger {
      li::before {
        background-color: var(--text-color-danger);
      }
    }
  }

  &__ol-list {
    list-style: auto;
    margin-left: 30px;

    li {
      padding-left: 5px;
      position: relative;
      line-height: 140%;
    }
  }

  &__dropdown-list {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__row {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.honored-card {
  margin-bottom: 100px;

  &:last-child {
    margin-bottom: 0;
  }

  &__img {
    width: 572px;
  }

  h3 {
    margin-bottom: 30px;
  }

  p {
    font-size: 1rem;
    color: var(--text-color);
    line-height: 140%;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 100px;
    }

    a {
      display: inline !important;
      font-size: 1rem;
      color: var(--text-color-hover);
      line-height: 140%;
      &:hover {
        text-decoration: underline;
      }
    }
    strong, b {
      font-weight: 700;
    }
  
    i {
      font-style: italic;
    }
  }
  
  blockqoute {
    display: block;
    padding: 20px;
    background-color: var(--bg-light);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul, ol {
    padding-left: 18px;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 100px;
    }

    li {
      font-size: 1rem;
      color: var(--text-color);
      line-height: 140%;
    }

    a {
      display: inline !important;
      font-size: 1rem;
      color: var(--text-color-hover);
      line-height: 140%;
      &:hover {
        text-decoration: underline;
      }
    }
    
    strong, b {
      font-weight: 700;
    }
  
    i {
      font-style: italic;
    }
  }
}

.pre-courses-page {
  &__img {
    width: 770px;
    height: 875px;
    background-color: #c4c4c4;
  }
}

.ovz-slider {
  position: relative;

  &__text {
    position: absolute;
    top: calc(100% + 52px);
    left: 0;
    width: 100%;
  }

  &__controls {
    position: absolute;
    top: calc(100% + 126px);
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__prev,
  &__next {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: 100%;
      height: 2px;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 100%;
        background-color: var(--text-color);
        transition: all 0.3s ease-in-out;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &:hover {
      span {
        &::before,
        &::after {
          background-color: var(--text-color-hover);
        }
      }
    }
  }

  &__prev {
    span {
      &::before,
      &::after {
        transform-origin: left center;
      }
    }
  }

  &__next {
    span {
      &::before,
      &::after {
        transform-origin: right center;
        left: 9px;
      }
    }
  }
}

.dropdown-list {
  &__item {
    overflow: hidden;
    margin-bottom: 25px;
  }
  &__link {
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--text-color);

    &:hover {
      color: var(--text-color-hover);

      span {
        &::before,
        &::after {
          background: var(--text-color-hover);
        }
      }
    }

    &_active {
      span {
        transform-origin: center center;
        transform: rotate(-180deg);
      }
    }

    span {
      width: 24px;
      height: 1px;
      display: block;
      position: relative;
      transition: all 0.3s ease-in-out;
      margin-left: 25px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 14px;
        height: 1px;
        background: var(--text-color);
        transform-origin: center center;
        transition: all 0.3s ease-in-out;
      }

      &::before {
        transform: rotate(45deg);
        left: 0;
      }

      &::after {
        transform: rotate(-45deg);
        left: 10px;
      }
    }

    span.small-angle {
      width: 15px;

      &::before,
      &::after {
        width: 10px;
      }

      &::after {
        left: 7px;
      }
    }
  }

  &__data {
    height: 0;
  }
}

.kolachev-conf-page {
  &__img {
    width: 530px;
  }
}

.pps-70-page {
  &__img {
    width: 100%;
  }
}

.memory-page {
  &__card {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.memory-card {
  &__img {
    width: 572px;
  }
}

.allfaculty-page {
  &__card {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.allfaculty-card {
  &__img {
    width: 100%;
    height: 580px;
    background-color: #dedede;
  }
}

.faculty {
  &__row {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__imgs {
    img {
      width: 100%;
    }
    picture {
      display: block;
      margin-bottom: 250px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.contacts-page {
  &__group {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dropdown-list {
    margin-top: 100px;
  }

  &__map {
    height: 580px;
  }
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 550px;

  &__group {
    display: flex;
  }
}

.history-page {
  &__main-img {
    width: 100%;
    height: 921px;
    background-color: #c4c4c4;
    margin-bottom: 100px;
  }

  &__img {
    margin: 100px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_vertical {
    width: 402px;
  }
}

.memory-PavlovEI-page {
  &__video {
    width: 100%;
    height: 519.91px;
    background-color: #c4c4c4;
    margin-bottom: 50px;
  }
}

.resurs-page {
  &__img {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media (max-width: 1792.99px) {
  .page {
    &__text {
      width: 670px;
    }

    .container {
      &__col {
        .page__text {
          width: 870px;
        }
      }
    }
  }

  .contacts-page {
    &__map {
      height: 540px;
    }
  }
}

@media (max-width: 1459.99px) {
  .page {
    &__text {
      width: 640px;
    }
  }

  .contacts-page {
    &__map {
      height: 440px;
    }
  }
}

@media (max-width: 1319.99px) {
  .page {
    &__title {
      padding-bottom: 50px;
    }

    &__text {
      width: 480px;
    }

    .container {
      &__col {
        margin-left: 0;
      }
    }
  }

  .biblio-page {
    .page {
      &__text {
        width: 100%;
      }

      &__row {
        &:nth-child(3) {
          display: flex;
          flex-wrap: wrap;

          .container {
            &__left {
              order: 2;
              margin-bottom: 0;
            }

            &__right {
              order: 1;
              margin-bottom: 50px;
            }
          }
        }
      }
    }

    .container__left {
      margin-bottom: 50px;
    }
  }

  .honored-card {
    .container {
      flex-wrap: nowrap;

      &__left {
        width: 480px;
        margin-right: 20px;
      }

      &__right {
        width: 460px;
      }
    }
  }
  .allfaculty-page {
    .container {
      flex-wrap: nowrap;

      &__left {
        width: 480px;
      }

      &__right {
        width: 460px;
        margin-left: 20px;
      }
    }
  }

  .cookies-page {
    &__group {
      .page__text {
        width: 100%;
      }
    }
  }

  .pre-courses-page {
    .container {
      &__left {
        order: 2;
      }

      &__right {
        order: 1;
      }
    }
  }

  .kolachev-conf-page {
    .container {
      flex-wrap: nowrap;

      &__left {
        width: 480px;
      }

      &__right {
        width: 460px;
        margin-left: 20px;
      }
    }

    &___img {
      width: 100%;
    }
  }

  .memory-page {
    .container {
      flex-wrap: nowrap;

      &__left {
        width: 480px;
      }

      &__right {
        width: 460px;
        margin-left: 20px;
      }
    }

    &___img {
      width: 100%;
    }
  }

  .ovz-room-page {
    .container {
      flex-wrap: nowrap;

      &__left {
        width: 480px;
      }

      &__right {
        width: 460px;
        margin-left: 20px;
      }
    }

    &___img {
      width: 100%;
    }
  }

  .contacts-page {
    .container {
      &__left {
        order: 2;
      }

      &__right {
        order: 1;
      }
    }

    &__map {
      height: 400px;
    }

    .page__subtitle {
      margin-top: 50px;
    }
  }
}

@media (max-width: 999.99px) {
  .page {
    &__title {
      padding-bottom: 50px;
    }

    &__subtitle {
      margin-top: 50px;
    }

    &__text {
      width: 100%;
    }

    .container {
      &__col {
        width: 100%;
        .page__text {
          width: 100%;
        }
      }
    }
  }

  .honored-card {
    .container {
      flex-wrap: wrap;

      &__left {
        width: 100%;
        margin-right: 0;
        order: 2;
      }

      &__right {
        width: 460px;
        order: 1;
        margin-bottom: 50px;
      }
    }
  }

  .allfaculty-page {
    .container {
      flex-wrap: wrap;

      &__left {
        width: 100%;
      }

      &__right {
        width: 100%;
        margin-left: 0;
      }
    }

    .page__subtext {
      margin-bottom: 25px;
    }
  }

  .pre-courses-page {
    &__img {
      margin-bottom: 50px;
    }
  }

  .kolachev-conf-page {
    .container {
      flex-wrap: wrap;

      &__left {
        width: 100%;
        order: 2;
      }

      &__right {
        width: 100%;
        margin-left: 0;
        order: 1;
      }
    }

    &__img {
      width: 400px;
      margin-bottom: 30px;
    }
  }

  .memory-page {
    .container {
      flex-wrap: wrap;

      &__left {
        width: 100%;
        order: 2;
      }

      &__right {
        width: 100%;
        margin-left: 0;
        order: 1;
      }
    }
  }

  .memory-card {
    &__img {
      width: 400px;
      margin-bottom: 30px;
    }
  }

  .ovz-room-page {
    .container {
      flex-wrap: wrap;

      &__left {
        width: 100%;
        order: 2;
      }

      &__right {
        width: 100%;
        margin-left: 0;
        order: 1;
      }
    }

    &__slider {
      margin-bottom: 200px;
    }
  }

  .memory-PavlovEI-page__video {
    height: auto;
  }

  .contacts-page {
    &__map {
      height: 350px;
    }
  }
}

@media (max-width: 639.99px) {
  .contacts-page {
    &__map {
      height: 300px;
    }

    .page__subtitle {
      margin-top: 30px;
    }

    .dropdown-list__link {
      justify-content: space-between;

      h3 {
        width: 250px;
      }
    }
  }

  .contact {
    flex-wrap: wrap;
    width: 100%;

    &__title {
      width: 100%;
      margin-bottom: 10px;
    }

    &__text {
      width: 100%;
    }
  }
}
