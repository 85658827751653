.schedule-page {
  &__group {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-end;
  }

  &__switch {
    margin-left: 100px;
    margin-bottom: 3px;

    &_alone {
      margin-left: 0;
    }
  }

  &__form {
    margin-bottom: 100px;
  }

  &__desc {
    margin-top: 100px;
    margin-bottom: 50px;
    border-bottom: 10px solid var(--bg-dark);
    opacity: 0;
    height: 0;
  }

  &__info {
    margin-bottom: 150px;
    opacity: 0;
  }
}

.schedule-form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 30px;
  row-gap: 5px;
  &__group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__label {
    margin-bottom: 25px;

    &_disabled {
      color: var(--text-color-disabled);
    }
  }

  &__submit {
    width: auto;
    margin-bottom: 25px;
  }

  &__input {
    margin-bottom: 0;
  }

  &__fio {
    display: none;
  }
}

.schedule-card {
  padding: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:nth-child(2n - 1) {
    background-color: var(--bg-light);
  }

  &__day {
    width: 120px;
    display: flex;
    flex-direction: column;
  }

  &__date {
    margin-top: 15px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__time {
    width: 150px;
  }

  &__type {
    width: 60px;
  }

  &__name {
    width: 450px;
  }

  &__teacher {
    width: 200px;
  }
}

@media (max-width: 1459.99px) {
  .schedule-form {
    flex-wrap: wrap;
  }
}

@media (max-width: 1319.99px) {
  .schedule-card {
    &__name {
      width: 360px;
    }

    &__teacher {
      width: 150px;
    }
  }

  .schedule-form {
    column-gap: 10px;
  }
}

@media (max-width: 999.99px) {
  .schedule-page {
    &__group {
      flex-wrap: wrap;
    }

    &__week-number {
      width: 100%;
    }

    &__switch {
      margin-left: 0;
      margin-top: 25px;
      width: 100%;
    }

    &__desc {
      margin-top: 15px;
    }
  }

  .schedule-card {
    padding: 10px;

    &__day {
      width: 70px;
    }

    &__date {
      font-size: 0.75rem;
    }

    &__time {
      width: 110px;
    }

    &__type {
      width: 45px;
    }

    &__name {
      width: 175px;
    }

    &__teacher {
      width: 140px;
    }
  }
}

@media (max-width: 639.99px) {
  .schedule-form {
    &__group {
      width: 100%;
    }

    &__submit {
      width: 100%;
    }

    .form__group {
      width: 100%;
    }
  }

  .schedule-card {
    &__row {
      flex-wrap: wrap;
    }

    &__day {
      width: 77px;
    }

    &__group,
    &__time,
    &__type,
    &__name,
    &__teacher,
    &__place {
      width: 183px;
    }

    &__time,
    &__type,
    &__name,
    &__teacher {
      margin-bottom: 10px;
    }

    &__time {
      span {
        font-weight: 700;
      }
    }
  }
}
