.search-result-page {
  &__form {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .search-form {

    &__group {
      width: 500px;
      border-color: var(--text-color);
    }

    &__input {
      color: var(--text-color);
    }

    &__submit {
      color: var(--text-color);

      &:hover {
        color: var(--text-color-hover);
      }
    }
  }
}

.search-filter {
  margin-top: 25px;
}

.search-result {
  margin-top: 50px;
  max-height: 1129px;
}

.search-result-block {
  opacity: 0;
  pointer-events: none;
  height: 0;

  &:first-child {
    opacity: 1;
    pointer-events: auto;
    height: auto;
  }
  &__item {
    width: 870px;
    margin-bottom: 30px;
  }

  &__link {
    margin-bottom: 10px;
  }
}