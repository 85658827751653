:root {
  --font-family: "Roboto";
  --font-size: 1em;
  --letter-spacing: normal;

  --bg-light: #{$bg-light};
  --bg-dark: #{$bg-dark};
  --bg: #{$bg};
  --bg-disabled: #{$bg-disabled};
  --bg-primary: #{$bg-primary};

  --text-color: #{$text-color};
  --text-color-light: #{$text-color-light};
  --text-color-hover: #{$text-color-hover};
  --text-color-inactive: #{$text-color-inactive};
  --text-color-disabled: #{$text-color-disabled};
  --text-color-danger: #{$text-color-danger};
  --link-color: #{$link-color};
  --link-color-hover: #{$link-color-hover};

  --btn-bg: #{$btn-bg};
  --btn-bg-hover: #{$btn-bg-hover};
  --btn-color: #{$btn-color};
  --btn-color-transparent: #{$btn-color-transparent};

  --input-color: #{$input-color};
  --input-color-focus: #{$input-color-focus};
  --form-color-error: #{$form-color-error};

  --nav-width: 74px;

  --h1-size: #{$h1-size};
  --h1-weight: #{$h1-weight};
  --h1-lh: #{$h1-lh};

  --h2-size: #{$h2-size};
  --h2-weight: #{$h2-weight};
  --h2-lh: #{$h2-lh};

  --h3-size: #{$h3-size};
  --h3-weight: #{$h3-weight};
  --h3-lh: #{$h3-lh};

  --h4-size: #{$h4-size};
  --h4-weight: #{$h4-weight};
  --h4-lh: #{$h4-lh};

  --text1-size: #{$text1-size};
  --text1-weight: #{$text1-weight};
  --text1-lh: #{$text1-lh};

  --text2-size: #{$text2-size};
  --text2-weight: #{$text2-weight};
  --text2-lh: #{$text2-lh};

  --text3-size: #{$text3-size};
  --text3-weight: #{$text3-weight};
  --text3-lh: #{$text3-lh};

  --text4-size: #{$text4-size};
  --text4-weight: #{$text4-weight};
  --text4-lh: #{$text4-lh};

  --text5-size: #{$text5-size};
  --text5-weight: #{$text5-weight};
  --text5-lh: #{$text5-lh};

  --text-num-size: #{$text-num-size};
  --text-num-weight: #{$text-num-weight};
  --text-num-lh: #{$text-num-lh};

  --btn-size: #{$btn-size};
  --btn-weight: #{$btn-weight};
  --btn-ls: #{$btn-ls};

  --table-font-size: #{$table-font-size};
  --table-font-size-large: #{$table-font-size-large};
  --table-font-weight: #{$table-font-weight};
  --table-lh: #{$table-lh};
}

html,
body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  letter-spacing: var(--letter-spacing);
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  width: 100vw;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class */
ul,
ol {
  list-style: none;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  span {
    pointer-events: none;
  }
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

video {
  width: 100%;
  height: 100%;
}

button {
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  font-family: inherit;

  span {
    pointer-events: none;
  }
}

h1,
h2,
h3,
h4 {
  color: var(--text-color);
}

h1 {
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: var(--h1-lh);
  margin: 0;
}

h2 {
  font-size: var(--h2-size);
  font-weight: var(--h2-weight);
  line-height: var(--h2-lh);
}

h3 {
  font-size: var(--h3-size);
  font-weight: var(--h3-weight);
  line-height: var(--h3-lh);
}

h4 {
  font-size: var(--h4-size);
  font-weight: var(--h4-weight);
  line-height: var(--h4-lh);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: #008AD155;
  border-radius: 3px;

  &:hover {
    background-color: #008AD1ff;
  }
}

::-webkit-scrollbar-track {
  background-color: var(--bg-dark);
}

.ul-list {
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    font-size: var(--text3-size);
    font-weight: var(--text3-weight);
    line-height: var(--text3-lh);
    color: var(--text-color);
  }
}

.ol-list {
  list-style-type: decimal;
  padding-left: 25px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    font-size: var(--text3-size);
    font-weight: var(--text3-weight);
    line-height: var(--text3-lh);
    color: var(--text-color);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  &_sm {
    .modal__body {
      width: 500px;
      padding: 30px;
    }

    .modal__close {
      top: 15px;
      right: 15px;
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.7);
  }

  &__close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;

    span {
      position: relative;
      width: 100%;
      height: 2px;
      border-radius: 10px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transform-origin: center center;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &__body {
    background-color: var(--bg);
    padding: 75px;
    width: 1000px;
    position: relative;
  }

  &__title {
    margin-bottom: 25px;
  }
}

.request-modal {
  opacity: 0;
  pointer-events: none;
}

.request-confirm-modal {
  opacity: 0;
  pointer-events: none;
}

.request-form {
  margin-top: 50px;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__col {
    width: 400px;
  }

  &__btn {
    margin-top: 25px;
  }

  &__info {
    margin-top: 25px;
  }

  &__textarea {
    min-height: 235px !important;
  }
}

.form {
  &__group {
    position: relative;
  }

  &__input {
    font-size: 1rem;
    padding: 21px 30px;
    border: 1px solid var(--input-color);
    width: 100%;
    margin-bottom: 25px;
    color: var(--text-color);

    &:focus {
      border: 1px solid var(--input-color-focus);
    }

    &_error {
      border: 1px solid var(--form-color-error);
    }
  }

  &__textarea {
    font-family: inherit;
    font-size: 1rem;
    padding: 19px 30px;
    border: 1px solid var(--input-color);
    width: 100%;
    margin-bottom: 25px;
    min-height: 230px;
    color: var(--text-color);

    &:focus {
      border: 1px solid var(--input-color-focus);
    }

    &_error {
      border: 1px solid var(--form-color-error);
    }
  }

  &__text-error {
    position: absolute;
    bottom: 9px;
    left: 0;
    font-size: 0.75rem;
    color: var(--form-color-error);
    margin-left: 10px;
  }

  &__submit {
    width: 100%;
  }

  &__label {
    margin-bottom: 25px;

    &_disabled {
      color: var(--text-color-disabled);
    }
  }

  &__select {
    display: none;
  }
}

.custom-select {
  position: relative;
  background-color: var(--bg);
  border: 1px solid var(--input-color);
  width: 275px;
  margin-bottom: 25px;

  &_error {
    border: 1px solid var(--form-color-error);
  }

  &_active {
    border: 1px solid var(--input-color-focus);
  }

  &_disabled {
    background-color: var(--bg-disabled);
    border: 1px solid var(--bg-disabled);

    .custom-select__open {
      color: var(--text-color-disabled);

      &-arrow {

        &::before,
        &::after {
          background-color: var(--text-color-disabled);
        }
      }
    }
  }

  &__open {
    padding: 19px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    span {
      pointer-events: none;
    }

    &_active {
      .custom-select__open-arrow {
        &::before {
          transform: rotate(135deg) !important;
        }

        &::after {
          transform: rotate(-135deg) !important;
        }
      }
    }

    &-arrow {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 1px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: var(--text-color);
        transform-origin: center center;
        transition: all 0.3s ease-in-out;
      }

      &::before {
        transform: rotate(45deg);
        left: -1.5px;
      }

      &::after {
        transform: rotate(-45deg);
        right: -1.5px;
      }
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: -1px;
    background-color: var(--bg);
    border-bottom: 1px solid var(--input-color-focus);
    border-left: 1px solid var(--input-color-focus);
    border-right: 1px solid var(--input-color-focus);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    transform-origin: center top;
    width: calc(100% + 2px);
    max-height: 240px;
    overflow-y: auto;
    z-index: 1000;

    &::-webkit-scrollbar {
      width: 58px;
      /* ширина для вертикального скролла */
      height: 18px;
      /* высота для горизонтального скролла */
      background-color: var(--bg);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius: 10000px;
      border-left: 28px solid transparent;
      border-right: 28px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &_active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__option {
    padding: 19px 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.signature-modal {
  opacity: 0;
  pointer-events: none;

  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  &__property {
    width: 135px;
    margin-right: 35px;
    flex-shrink: 0;
  }

  &__hash-field {
    display: none;
    margin-top: 30px;
    min-height: 120px;
    margin-bottom: 0;
    resize: none;

    &_active {
      display: block;
    }
  }
}

.pre-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg);
  z-index: 10000;
  opacity: 1;
}

.text {
  color: var(--text-color);

  &_light {
    color: var(--text-color-light);
  }

  &_link {
    position: relative;
    color: var(--text-color-hover);

    &:hover {
      color: var(--btn-bg-hover);
    }
  }

  &_danger {
    color: var(--text-color-danger);
  }

  &_bold {
    font-weight: 700;
  }

  a.text_link {
    display: inline;
  }

  &_1 {
    font-size: var(--text1-size);
    font-weight: var(--text1-weight);
    line-height: var(--text1-lh);
  }

  &_2 {
    font-size: var(--text2-size);
    font-weight: var(--text2-weight);
    line-height: var(--text2-lh);
  }

  &_3 {
    font-size: var(--text3-size);
    font-weight: var(--text3-weight);
    line-height: var(--text3-lh);
  }

  &_4 {
    font-size: var(--text4-size);
    font-weight: var(--text4-weight);
    line-height: var(--text4-lh);
  }

  &_5 {
    font-size: var(--text5-size);
    font-weight: var(--text5-weight);
    line-height: var(--text5-lh);
  }

  &_nums {
    font-size: var(--text-num-size);
    font-weight: var(--text-num-weight);
    line-height: var(--text-num-lh);
  }
}

.btn {
  font-size: var(--btn-size);
  font-weight: var(--btn-weight);
  letter-spacing: var(--btn-ls);
  background: var(--btn-bg);
  padding: 22px 50px;
  color: var(--btn-color);
  text-transform: uppercase;

  &_active {
    color: var(--btn-color) !important;
    background: var(--btn-bg) !important;
  }

  &:hover {
    background: var(--btn-bg-hover);
  }

  &_transparent {
    background: transparent;
    color: var(--btn-color-transparent);
    border: 1px solid var(--btn-bg);
    padding: 21px 49px;

    &:hover {
      background: var(--btn-bg);
      color: var(--btn-color);
    }
  }
}

.btn-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .btn {
    border-right-width: 0;

    &:last-child {
      border-right-width: 1px;
    }
  }
}

.main-content {
  margin-right: calc(var(--nav-width) + 6px);
  z-index: 10;

  &_mt {
    margin-top: -161px;
  }
}

.header {
  padding-top: 50px;
  position: relative;
  z-index: 100;
  margin-right: var(--nav-width);

  &__logo {
    width: 280px;
  }
}

.logo {
  &__svg {
    width: 100%;
    pointer-events: none;
  }
}

.menu-index {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 75px;
  row-gap: 20px;

  &__item {

    &_dropdown {
      position: relative;
      display: inline-block;

      &:hover .submenu-index {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__link {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 140%;
    color: var(--text-color);

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.submenu-index {
  display: block;
  position: absolute;
  margin-left: -15px;
  padding-top: 20px;
  width: 215px;
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &__item {
    padding: 12.5px 15px;
    background: var(--bg-light);
    cursor: pointer;

    &:hover .submenu-index__link {
      color: var(--text-color-hover);
    }
  }

  &__link {
    font-size: 0.875rem;
    color: var(--text-color);
  }
}

.breadcrumbs {
  margin-top: 50px;

  &__item,
  &__link,
  &__divider {
    display: inline;
  }

  &__divider {
    margin: 0 5px;
  }

  &__link {
    &_active {
      pointer-events: none;
    }
  }
}

.section {
  margin-bottom: 200px;

  &__title {
    margin-bottom: 100px;
  }

  &__main-title {
    display: block;
    margin: 50px 0;
  }

  &__subtitle {
    margin-top: 85px;
    margin-bottom: 50px;
  }

  &__text {
    max-width: 870px;
    width: 100%;
    margin-bottom: 25px;

    &_strong {
      font-weight: 700;
    }
  }

  &__table {
    margin-top: 65px;
  }
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  width: var(--nav-width);
  height: 100vh;
  background: var(--bg-dark);
  padding: 50px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 10000;

  &__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__btn {
    color: var(--text-color-light);
    font-size: 1.5em;
    margin-bottom: 50px;

    &:first-child {
      margin-bottom: 85px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: var(--text-color-hover);
    }

    span {
      pointer-events: none;
    }
  }
}

.container {
  max-width: 1780px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__left {
    width: 870px;

    &_pl {
      padding-left: 130px;
    }
  }

  &__right {
    margin-left: 100px;
    width: 770px;
  }

  &__col {
    width: 870px;
    margin-left: 250px;
  }

  &_block {
    display: block;
  }

  &_pl {
    padding-left: 130px;
  }
}

.main-menu-btn {
  position: relative;
  width: 1.875rem;
  padding: 0.875rem 0;
  margin-top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  span {
    position: relative;
    display: block;
    width: 1.25rem;
    height: 0.125rem;
    background-color: var(--text-color-light);
    transition: all 0.3s ease-in-out;
    pointer-events: none;

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--text-color-light);
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      transform: translateY(-0.375rem);
    }

    &::after {
      transform: translateY(0.375rem);
    }
  }

  &:hover {
    span {
      background-color: var(--text-color-hover);

      &::before,
      &::after {
        background-color: var(--text-color-hover);
      }
    }
  }

  &_active {
    span {
      background-color: transparent !important;

      &::before {
        transform: rotate(45deg);
        transform-origin: center center;
      }

      &::after {
        transform: rotate(-45deg);
        transform-origin: center center;
      }
    }
  }
}

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--nav-width));
  z-index: 10000;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  &__container {
    display: flex;
    justify-content: space-between;
    height: auto;
    min-height: 100%;
  }

  &__left {
    width: 32.6%;
    height: 100vh;
    background: var(--bg);
    padding: 50px 0 50px 50px;
    transform: scaleX(0);
    transform-origin: right center;
  }

  &__center {
    display: flex;
    width: 47.8%;
    height: 100vh;
    padding: 50px 0;
    background: var(--bg-light);
    overflow-y: scroll;
    transform: scaleX(0);
    transform-origin: right center;
  }

  &__right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 19.6%;
    height: 100vh;
    background: var(--bg-dark);
    padding: 50px 50px 50px 0;
    transform: scaleX(0);
    transform-origin: right center;
  }

  &__logo {
    width: 200px;
    opacity: 0;
  }
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--nav-width));
  z-index: 10000;
  overflow: hidden;
  background-color: var(--bg-dark);
  transform-origin: right center;
  transform: scaleX(0);

  &__container {
    display: flex;
    height: auto;
    min-height: 100%;
    opacity: 0;
  }

  &__left {
    width: 600px;
    height: 100vh;
    padding: 50px;
  }

  &__center {
    display: flex;
    align-items: flex-start;
    width: 1320px;
    height: 100vh;
    padding: 50px;
  }

  &__logo {
    width: 200px;
  }

  &__group,
  &__close {
    margin-top: 210px;
  }

  &__close {
    margin-top: 215px;
    margin-left: 100px;
    font-size: 1.375rem;
    color: var(--text-color-light);
  }

  &__title {
    color: var(--text-color-light);
  }

  &__popular {
    margin-top: 50px;
  }
}

.popular-requests {
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  &__link {
    color: var(--text-color-light);

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.search-form {
  display: block;
  margin-top: 115px;

  &__group {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    width: 100%;
    border: 1px solid var(--bg);
  }

  &__input {
    width: 100%;
    margin-right: 30px;
    background-color: transparent;
    outline: none;
    border: 0;
    color: var(--text-color-light);
  }

  &__submit {
    color: var(--text-color-light);
    font-size: 1.5rem;
    margin-left: auto;

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.submenu-back {
  display: none;
}

.menu {
  opacity: 0;
  margin-top: 70px;
  width: 100%;
  padding-right: 25px;

  &__item {
    margin-bottom: 45px;
  }

  &__link {
    color: var(--text-color);

    &:hover {
      color: var(--text-color-hover);
    }

    &_active {
      color: var(--text-color-hover);
    }
  }

  &__open-submenu {
    display: none;
  }
}

.submenu-first-wrapper {
  position: relative;
  margin-top: 150px;
  margin-left: 100px;
  height: auto;
  width: 400px;
}

.submenu-second-wrapper {
  position: relative;
  margin-top: 150px;
  margin-left: 100px;
  height: auto;
  margin-left: 50px;
  width: 400px;
}

.submenu {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  &_active {
    opacity: 1;
    pointer-events: auto;
  }

  &__item {
    padding-bottom: 45px;

    &:last-child {
      padding-bottom: 150px;
    }
  }

  &__link {
    color: var(--text-color);

    &:hover {
      color: var(--text-color-hover);
    }

    &_active {
      color: var(--text-color-hover);
    }
  }

  &__open-submenu {
    display: none;
  }
}

.menu-info {
  opacity: 0;
  margin-left: 50px;
  margin-bottom: 30px;

  &__group {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    color: var(--text-color-light);
    line-height: 170%;

    a {
      color: var(--text-color-light);
    }
  }
}

.social-nav {
  margin-top: auto;

  &__item {
    margin-bottom: 50px;
  }
}

.table {
  font-size: $table-font-size;
  font-weight: $table-font-weight;
  line-height: $table-lh;
  width: 100%;
  border: none;
  border-collapse: collapse;

  &_small {
    width: 870px;
  }

  &_medium {
    width: 1400px;
  }

  &_wh {
    border-bottom: 3px solid var(--bg-dark);

    tr {
      &:nth-child(2n) {
        background: var(--bg-light) !important;
      }

      &:nth-child(2n-1) {
        background: var(--bg) !important;
      }
    }
  }

  &_large-fz {
    font-size: $table-font-size-large;
  }

  &_small {
    max-width: 1300px;
    width: 100%;
  }

  tr {
    vertical-align: top;

    &:nth-child(2n-1) {
      background: var(--bg-light);
    }
  }

  th {
    padding: 16px 30px;
    background: var(--bg-dark);
    text-align: left;
    color: var(--text-color-light);
  }

  td {
    padding: 16px 30px;
    color: var(--text-color);
  }
}

.footer {
  padding: 100px 0 25px 0;
  margin-right: var(--nav-width);
  background: var(--bg-dark);
  color: var(--text-color-light);

  &__divider {
    width: 100%;
    height: 1px;
    background: var(--bg);
    margin: 100px 0;
  }

  &__title {
    margin-bottom: 50px;
    color: var(--text-color-light);

    &_mt {
      margin-top: 100px;
    }
  }

  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__col {
    max-width: 290px;
    margin-left: 100px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__text {
    color: var(--text-color-light);
    margin-bottom: 25px;

    a {
      color: var(--text-color-light);
    }
  }
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__item {
    margin-right: 132px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    font-size: 1rem;
    color: var(--text-color-light);
    margin-bottom: 30px;
    font-weight: 500;
    height: 40px;

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.footer-submenu {
  &__item {
    margin-bottom: 30px;
  }

  &__link {
    font-size: 1rem;
    color: var(--text-color-light);

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.footer-subsubmenu {
  &__item {
    margin-top: 30px;
  }

  &__link {
    margin-left: 30px;
    font-size: 0.875rem;
    color: var(--text-color-light);

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 100px;
  height: 196px;
  margin-top: 86px;

  &__link {
    width: 300px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 120%;
    color: var(--text-color-light);
    text-transform: uppercase;

    &:hover {
      color: var(--text-color-hover);
    }
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;

  &__text {
    color: var(--text-color-light);

    a {
      color: var(--text-color-light);

      &:hover {
        color: var(--text-color-hover);
      }
    }
  }
}

.card {
  width: 420px;

  &:last-child {
    margin-right: 0;
  }

  &__link-img {
    margin-bottom: 30px;
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      max-width: none !important;
    }
  }

  &__date {
    display: inline-block;
    margin-bottom: 12px;
  }

  &__link {
    display: block;
    margin-bottom: 30px;

    &:hover h3 {
      color: var(--text-color-hover);
    }
  }

  &__title {
    color: var(--text-color);
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }

  &__img {
    pointer-events: none;
  }
}

.visually-impaired-tools {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: var(--nav-width);
  margin-top: -100px;
  transition: all 0.3s ease-in-out;
  background-color: var(--bg);

  &_active {
    margin-top: 0 !important;
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-title,
  &__title {
    display: none;
  }

  &__group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-light);
    font-weight: 500;
    color: var(--text-color);
    border: 3px solid transparent;
    margin-right: 10px;
    font-size: 1.375rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: var(--btn-color-transparent);
    }

    &_active {
      border-color: var(--btn-color-transparent);
    }

    &_fz {
      &_1 {
        font-size: 1.125rem;
      }

      &_2 {
        font-size: 1.375rem;
      }

      &_3 {
        font-size: 1.625rem;
      }
    }

    &_color {

      &_wb {
        background-color: #eeeeee;
      }

      &_bw {
        background: #000000;
        color: #ffffff;
      }

      &_c {
        background-color: #9dd1ff;
        color: #14305e;
      }
    }

    &_letter-space {
      font-size: 1.125rem;

      &_n {
        letter-spacing: normal;
      }

      &_1 {
        letter-spacing: 1px;
      }

      &_2 {
        letter-spacing: 2px;
      }
    }

    &_font {
      &_serif {
        font-family: "Georgia" !important;
        font-weight: 700;
      }

      &_sans-serif {
        font-family: "Roboto" !important;
      }
    }

    &_img {
      font-size: 1.5rem;
    }
  }

  &__clear {
    font-weight: 500;
    color: var(--text-color);
    font-size: 1.625rem;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;

    span {
      position: relative;
      width: 22px;
      height: 2px;
      background-color: transparent;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--text-color);
        transform-origin: center center;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

@media (max-width: 1792.99px) {
  .container {
    max-width: 1460px;

    &__left {
      width: 710px;

      &_pl {
        padding-left: 90px;
      }
    }

    &__right {
      margin-left: 40px;
      width: 670px;
    }

    &_pl {
      padding-left: 90px;
    }
  }

  .search {
    &__left {
      width: 600px;
    }

    &__center {
      width: 900px;
    }
  }

  .footer-menu {
    &__item {
      margin-right: 67px;
    }
  }
}

@media (max-width: 1459.99px) {
  :root {
    --nav-width: 60px;
  }

  .container {
    max-width: 1320px;

    &__left {
      width: 640px;

      &_pl {
        padding-left: 90px;
      }
    }

    &__right {
      margin-left: 30px;
      width: 610px;
    }

    &_pl {
      padding-left: 90px;
    }
  }

  .submenu_first {
    width: 300px;
  }

  .search {
    &__left {
      width: 500px;
    }

    &__center {
      width: 700px;
    }
  }

  .footer {
    &__col {
      margin-left: 40px;

      &:first-child {
        width: 264px;
      }
    }
  }

  .footer-menu {
    &__item {
      margin-right: 28px;
    }
  }

  .footer-links {
    column-gap: 70px;

    &__link {
      width: 260px;
    }
  }

  .custom-select {
    width: 243px;
  }
}

@media (max-width: 1319.99px) {
  :root {
    --nav-width: 0px;
  }

  .main-content {
    margin-right: 0;

    &_mt {
      margin-top: 0px;
    }
  }

  .nav {
    width: 45px;
    height: 45px;
    padding: 0;
    top: 25px;
    right: 20px;
    justify-content: center;
    border-radius: 50%;
    z-index: 100000;

    &__group {
      position: absolute;
      top: 7.5px;
      right: 0;
      width: calc(100vw - 32px);
      padding: 0 7px;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
    }

    &__btn {
      margin-top: 3px;
      margin-bottom: 0;

      &:first-child {
        margin: 0;
      }

      &:not(&:first-child) {
        opacity: 0;
        pointer-events: none;

        transition: none;

        &:hover {
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .social-nav {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
  }

  .main-menu-btn {
    margin-top: 0;
  }

  .main-menu {
    &__container {
      position: relative;
    }

    &__left,
    &__center {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__left {
      background-color: var(--bg-dark);
      padding: 120px 20px;
      transform: translateX(100%);
    }

    &__center {
      opacity: 0;
      pointer-events: none;
      transform: translateX(100%);
      z-index: 100001;
      position: relative;
    }

    &__right,
    &__logo {
      display: none;
    }
  }

  .submenu-back {
    position: fixed;
    top: 25px;
    right: 20px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--text-color);
    transform: rotate(180deg);
  }

  .menu {
    margin-top: 0;
    width: 50%;
    opacity: 0;

    &__item {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__link {
      color: var(--text-color-light);
      width: 400px;
    }

    &__open-submenu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      color: var(--text-color-light);
      transition: all 0.3s ease-in-out;
      font-size: 1.5rem;

      &:hover {
        color: var(--text-color-hover);
      }
    }
  }

  .submenu-first-wrapper {
    margin-top: 70px;
    margin-left: 0;
    padding: 0 20px;
    width: 100%;
  }

  .submenu-second-wrapper {
    display: none;
    margin-top: 70px;
    margin-left: 0;
    padding: 0 20px;
    width: 100%;
  }

  .submenu {
    width: 100%;
    padding: 0 20px;

    &__item {
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__link {
      width: 300px;
    }

    &__dropdown {
      margin-left: 30px;
    }

    &__dropdown-item {
      margin-top: 20px;
    }

    &__dropdown-link {
      color: var(--text-color);

      &:hover {
        color: var(--text-color-hover);
      }

      &_active {
        color: var(--text-color-hover);
      }
    }

    &__open-submenu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      color: var(--text-color);
      transition: all 0.3s ease-in-out;
      font-size: 1.5rem;

      &:hover {
        color: var(--text-color-hover);
      }
    }
  }

  .container {
    max-width: 1000px;
    flex-wrap: wrap;

    &__left {
      width: 100%;
    }

    &__right {
      margin-left: 0px;
      width: 100%;

      &_plt {
        padding-left: 90px;
        padding-top: 100px;
      }
    }
  }

  .header {
    padding-top: 25px;

    &__menu {
      display: none;
    }

    &_home {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .section {
    margin-bottom: 150px;

    &__title {
      margin-bottom: 50px;
    }
  }

  .search {
    &__left {
      width: 500px;
    }

    &__center {
      width: 500px;
    }
  }

  .footer {
    .container {
      max-width: 1000px;
      flex-wrap: nowrap;

      &__left {
        width: 480px;
      }

      &__right {
        width: 480px;
      }
    }

    &__divider {
      display: none;
    }

    &__group {
      display: block;
    }

    &__col:last-child {
      margin-left: 0;
      margin-top: 30px;
    }
  }

  .footer-menu {
    display: none;
  }

  .footer-links {
    height: auto;
  }

  .card {
    width: 300px;

    &__link-img {
      height: 200px;
    }
  }

  .visually-impaired-tools {
    position: relative;
    margin-top: -162px;
    z-index: 1000000;

    &__row {
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      gap: 30px;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media (max-width: 999.99px) {
  :root {
    --h1-size: #{$h1-size-640};
    --h2-size: #{$h2-size-640};
    --h3-size: #{$h3-size-640};
    --h3-lh: #{$h3-lh-640};
    --text1-size: #{$text1-size-640};
    --text2-size: #{$text2-size-640};
    --text4-size: #{$text4-size-640};
    --text-num-size: #{$text-num-size-640};
  }

  .container {
    max-width: 640px;
    padding: 0 10px;

    &__left {
      &_pl {
        padding-left: 0;
      }
    }

    &__right {
      &_plt {
        padding-top: 100px;
        padding-left: 0;
      }
    }
  }

  .nav {
    top: 20px;
    right: 10px;
  }

  .submenu-back {
    top: 20px;
    right: 10px;
  }

  .menu {
    width: 100%;
    padding-right: 0;
  }

  .request-form {
    &__row {
      flex-wrap: wrap;
    }

    &__col {
      width: 100%;
    }

    &__textarea {
      margin-top: 25px;
    }
  }

  .header {
    padding-top: 20px;

    &__logo {
      width: 100px;
    }
  }

  .section {
    margin-bottom: 100px;
  }

  .card {
    &__link-img {
      margin-bottom: 20px;
    }

    &__link {
      margin-bottom: 20px;
    }
  }

  .search {
    &__container {
      display: block;
    }

    &__left {
      width: 500px;
      height: auto;
    }

    &__center {
      width: 700px;
      height: auto;
    }

    &__group {
      margin-top: 0;
    }

    &__close {
      margin-top: 0;
    }
  }

  .footer {
    .container {
      max-width: 640px;

      &__left {
        width: 290px;
        margin-right: 20px;
      }

      &__right {
        width: 310px;
      }
    }
  }

  .copyright {
    &__text {
      &:first-child {
        width: 290px;
        margin-right: 20px;
      }

      &:last-child {
        width: 310px;
        text-align: right;
      }
    }
  }

  .form {
    &__label {
      margin-bottom: 10px;
    }
  }

  .visually-impaired-tools {
    margin-top: -150px;

    &__clear {
      font-size: 1.375rem;
    }

    &__row {
      column-gap: 40px;
      row-gap: 20px;
    }

    &__icon {
      width: 40px;
      height: 40px;
    }

    &__close {
      width: 40px;
      height: 40px;
    }
  }

  .modal {
    align-items: flex-start;
    padding-top: 50px;

    &__body {
      width: 640px;
      padding: 65px 10px;
    }
  }
}

@media (max-width: 639.99px) {
  :root {
    --h1-size: #{$h1-size-320};
    --h2-size: #{$h2-size-320};
    --text1-size: #{$text1-size-320};
    --text2-size: #{$text2-size-320};
    --text3-size: #{$text3-size-320};
  }

  .btn {
    width: 100%;
    padding: 21px 45px;
    text-align: center;
    letter-spacing: 0.03rem;
  }

  .table {

    td,
    th {
      padding: 15px;
    }
  }

  .custom-select {
    width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .visually-impaired-tools {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    background-color: var(--bg);
    z-index: 1000000;
    pointer-events: none;
    opacity: 0;

    &__row {
      row-gap: 30px;
    }

    &__main-title {
      display: block;
      width: 166px;
    }

    &__group {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__title {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }

    &__clear {
      font-size: 1rem;
    }
  }

  .nav {
    top: 10px;
  }

  .menu {
    &__item {
      margin-bottom: 30px;
    }

    &__link {
      width: 280px;
    }
  }

  .submenu-back {
    top: 10px;
  }

  .submenu {
    &__item {
      padding-bottom: 30px;

      &:last-child {
        padding-bottom: 50px;
      }
    }
  }

  .search {

    &__left,
    &__center {
      width: 320px;
      padding: 25px 0;
      margin: 0 auto;
    }

    &__logo {
      width: 100px;
    }

    &__close {
      margin-left: 50px;
    }
  }

  .search-form {
    margin-top: 50px;
  }

  .footer {
    padding-top: 50px;

    .container {
      flex-wrap: wrap;
      max-width: 100%;

      &__left,
      &__right {
        width: 100%;
      }
    }

    &__title {
      margin-bottom: 30px;
    }

    &__col:last-child {
      margin-top: 30px;
    }
  }

  .footer-links {
    &__link {
      width: 100%;
    }
  }

  .copyright {
    flex-wrap: wrap;
    margin-top: 100px;

    &__text {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 100%;
        text-align: left;
        margin-top: 30px;
      }
    }
  }

  .modal {
    &__body {
      width: 320px;
    }
  }
}