/**
 * Variables
*/

/* Typography */
$h1-size: 4.125rem;
$h1-weight: 500;
$h1-lh: 120%;

$h1-size-640: 2.5625rem;
$h1-size-320: 1.875rem;

$h2-size: 2.5625rem;
$h2-weight: 500;
$h2-lh: 120%;

$h2-size-640: 2.25rem;
$h2-size-320: 1.625rem;

$h3-size: 1.625rem;
$h3-weight: 500;
$h3-lh: 140%;

$h3-size-640: 1.375rem;
$h3-lh-640: 120%;

$h4-size: 1rem;
$h4-weight: 500;
$h4-lh: 140%;

$text1-size: 1.625rem;
$text1-weight: 500;
$text1-lh: 120%;

$text1-size-640: 1.375rem;
$text1-size-320: 1rem;

$text2-size: 1.625rem;
$text2-weight: 300;
$text2-lh: 120%;

$text2-size-640: 1.375rem;
$text2-size-320: 1rem;

$text3-size: 1rem;
$text3-weight: 400;
$text3-lh: 140%;

$text3-size-320: 0.875rem;

$text4-size: 0.875rem;
$text4-weight: 400;
$text4-lh: 120%;

$text4-size-640: 0.75rem;

$text5-size: 0.625rem;
$text5-weight: 400;
$text5-lh: 120%;

$text-num-size: 2.3125rem;
$text-num-weight: 500;
$text-num-lh: 100%;

$text-num-size-640: 1.875rem;

$btn-size: 0.875rem;
$btn-weight: 500;
$btn-ls: 0.1rem;

$table-font-size: 1rem;
$table-font-size-large: 1.625rem;
$table-font-weight: 400;
$table-lh: 140%;

/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$bg-light: #f1f8fb;
$bg-dark: #111a26;
$bg: #ffffff;
$bg-disabled: #eeeeee;
$bg-primary: #008ad1;

$text-color: #121212;
$text-color-light: #ffffff;
$text-color-hover: #008ad1;
$text-color-inactive: #8d8d8d;
$text-color-disabled: #808080;
$text-color-danger: #f21f1f;
$link-color: #008ad1;
$link-color-hover: #115d85;

$btn-bg: #008ad1;
$btn-bg-hover: #115d85;
$btn-color: #ffffff;
$btn-color-transparent: #008ad1;

$input-color: #808080;
$input-color-focus: #008ad1;
$form-color-error: #f21f1f;

/* Components */
