.abitur-page {
  padding-bottom: 50px;

  &__title {
    color: var(--text-color-light);
    margin-bottom: 50px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
  }

  &__section {
    margin-top: 100px;

    &:last-child {
      margin-bottom: 100px;
    }
  }

  &__section-title {
    margin-bottom: 50px;
  }
}

.abitur-head {
  background-color: var(--bg-primary);
  display: flex;
  margin: 50px 0;
  overflow: hidden;

  &__group {
    width: 640px;
    padding: 50px;
  }

  &__subtitle {
    margin-bottom: 25px;
    color: var(--text-color-light);
  }

  &__text {
    margin-bottom: 20px;
    color: var(--text-color-light);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__banner img {
    width: 1100px;
    height: 560px;
  }
}

.abitur-nav {
  margin-top: 100px;
  width: 420px;
  padding-left: 50px;
  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    font-weight: 500;
    color: var(--link-color);
    position: relative;
    display: inline;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid var(--link-color);
    }

    &_active {
      border-bottom: 1px solid var(--link-color);
    }
  }
}

.abitur-content {
  width: 1320px;
}

.abitur-directions {
  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}

.abitur-directions-card {
  width: 420px;
  height: 300px;
  padding: 20px 30px;
  border: 1px solid var(--bg-primary);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  &__code {
    margin-bottom: 10px;
    display: inline-block;
  }

  &__title {
    height: 44px;
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 10px;

    span {
      font-weight: 500;
    }
  }
}

.abitur-courses {
  &__text {
    margin-bottom: 20px;
    width: 870px;

    &_caption {
      margin-top: 50px;
    }
  }

  .ul-list {
    margin-bottom: 50px;
  }
}

.abitur-steps {
  &__group {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
}

.abitur-step-card {
  width: 420px;

  &__title {
    margin-bottom: 25px;
  }

  &__text {
    margin-bottom: 20px;
  }
}

.abitur-faq {
  &__dropdown-list .dropdown-list__item {
    margin-bottom: 0;
  }

  &__text {
    margin-bottom: 20px;
    width: 870px;
    padding-left: 50px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 25px;
    }
  }
}

@media (max-width: 1792.99px) {
  .abitur-content {
    width: 1000px;
  }

  .abitur-head {
    &__banner img {
      width: 780px;
    }
  }
}

@media (max-width: 1459.99px) {
  .abitur-head {
    &__banner img {
      width: 640px;
    }
  }

  .abitur-nav {
    width: 410px;
    padding-left: 30px;
  }
  .abitur-content {
    width: 870px;
  }

  .abitur-faq {
    &__dropdown-list {
      .dropdown-list__link {
        width: 860px;
      }
    }
  }
}

@media (max-width: 1319.99px) {
  .abitur-head {

    &__group {
      padding: 20px;
      width: 543px;
    }
    &__banner {
      width: 417px;
      img {
        width: 417px;
      }
    }
  }

  .abitur-nav {
    display: none;
  }
}

@media (max-width: 999.99px) {
  .abitur-page {
    &__group {
      width: 100%;
    }
  }
  .abitur-content {
    width: 100%;
  }
  .abitur-head {
    flex-wrap: wrap;
    margin-bottom: 0;

    &__banner {
      order: 1;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__group {
      order: 2;
      width: 100%;
    }
  }

  .abitur-directions {
    &__cards {
      gap: 20px;
    }
  }

  .abitur-directions-card {
    width: 300px;
    height: 310px;
    padding: 20px 10px;

    &__title {
      height: 59px;
    }
  }

  .abitur-steps {
    &__group {
      gap: 20px;
    }
  }

  .abitur-step-card {
    width: 300px;
  }

  .abitur-courses {
    &__text {
      width: 100%;
    }
  }

  .abitur-faq {
    &__dropdown-list {
      .dropdown-list__link {
        width: 610px;
      }
    }

    &__text {
      width: 100%;
      padding-left: 30px;
    }
  }
}

@media (max-width: 639.99px) {
  .abitur-head {
    &__group {
      padding: 30px 10px;
    }
  }

  .abitur-steps {
    &__group {
      row-gap: 50px;
    }
  }

  .abitur-step-card {
    &__text {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .abitur-faq {
    &__dropdown-list {
      .dropdown-list__link {
        width: auto;
        justify-content: space-between;

        h4 {
          width: 256px;
        }

        .small-angle {
          width: 17px;
          margin-left: 0;
        }
      }
    }
  }
}
