.important {
  &__table-scroll {
    width: 100%;
    overflow-x: auto;
  }

  &__group {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__main-title {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  &__description {
    margin-bottom: 100px;

    // p {
    //   font-size: 1rem;
    //   color: var(--text-color);
    //   line-height: 140%;
    //   padding-bottom: 20px;
    //   max-width: 870px;
  
    //   &:last-child {
    //     padding-bottom: 100px;
    //   }
  
    //   a {
    //     display: inline !important;
    //     font-size: 1rem;
    //     color: var(--text-color-hover);
    //     line-height: 140%;

    //     &:hover {
    //       text-decoration: underline;
    //     }
    //   }

    //   strong, b {
    //     font-weight: 700;
    //   }
    
    //   i {
    //     font-style: italic;
    //   }
    // }
  
    
    // img, picture {
    //   display: block;
    //   width: 870px;
    //   margin-bottom: 100px;
    //   margin-top: 30px;
    // }
  
    // img.full {
    //   width: calc(100% + 250px);
    //   margin-left: -250px;
    //   max-width: none !important;
    // }
  
    // blockqoute {
    //   display: block;
    //   padding: 20px;
    //   background-color: var(--bg-light);
    // }
  
    // ul {
    //   list-style-type: disc;
    // }
  
    // ol {
    //   list-style-type: decimal;
    // }
  
    // ul, ol {
    //   padding-left: 18px;
    //   padding-bottom: 20px;
  
    //   &:last-child {
    //     padding-bottom: 100px;
    //   }
  
    //   li {
    //     font-size: 1rem;
    //     color: var(--text-color);
    //     line-height: 140%;
    //     max-width: 870px;
    //   }

    //   a {
    //     display: inline !important;
    //     font-size: 1rem;
    //     color: var(--text-color-hover);
    //     line-height: 140%;

    //     &:hover {
    //       text-decoration: underline;
    //     }
    //   }

    //   strong, b {
    //     font-weight: 700;
    //   }
    
    //   i {
    //     font-style: italic;
    //   }
    // }
  }

  &__text {
    font-family: "Roboto";
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    padding-bottom: 20px;
    width: 870px;
    color: #121212;

    &_ml {
      margin-left: 25px;
    }

    &_pt {
      padding-top: 50px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__link {
    display: inline;
    color: #008ad1;

    &:hover {
      color: #115d85;
    }

    &[href^="tel"],
    &[href^="mailto"] {
      display: inline !important;
    }
  }

  &__doc {
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
    width: 870px;
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__doc-icon {
    width: 40px;
    flex-shrink: 0;
  }

  &__doc-text {
    font-family: "Roboto";
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 15px;
  }

  &__doc-btns {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }

  &__doc-signature,
  &__doc-download {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 0.875rem;
    color: #000;

    span {
      font-size: 1.25rem;
    }

    &:hover {
      color: #008ad1;
    }
  }

  &__title {
    padding-bottom: 50px;
    padding-top: 50px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__strong {
    font-weight: 700;
  }

  &__subtitle {
    padding-bottom: 25px;
    padding-top: 50px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &_blue {
      color: #008ad1;
    }
  }

  &__table-wrapper {
    overflow-x: auto;
    margin-bottom: 50px;
  }

  &__table {
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
    border-bottom: 10px solid #111a26;
    border-collapse: collapse;
    margin-bottom: 50px;

    &_small {
      width: 870px;
    }

    &_medium {
      width: 1400px;
    }

    &-row-title {
      text-transform: uppercase;
      margin: 0 !important;
      padding: 0 !important;
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }

    tr {
      vertical-align: top;
    }

    thead {
      background-color: #111a26;
      color: #ffffff;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
    }

    td,
    th {
      padding: 15px;
      text-align: left;
      word-wrap: break-word;
      border-color: inherit;
      border-style: solid;
      border-width: 0;

      a {
        display: block;
      }
    }

    tbody {
      --bg-table-row: #f1f8fb;
      tr:nth-child(2n) {
        background-color: var(--bg-table-row);
      }
    }

    &_list {
      border-bottom: none;

      thead {
        display: none;
      }

      tr {
        display: flex;
        flex-direction: column;
        background-color: transparent !important;
        margin-bottom: 25px;
        height: 22px;
        overflow: hidden;
        margin-top: 25px;
      }

      td {
        padding: 0;
        padding-bottom: 45px;
        position: relative;
        --table-title: "";
        padding-left: 700px;
        //min-height: 22px;

        &:last-child {
          margin-bottom: 0ж;
        }

        &:first-child {
          --open-btn-text: "";
          transition: all 0.5s ease-in-out;
          margin-top: 47px;

          &::after {
            content: var(--open-btn-text);
            position: absolute;
            top: -47px;
            left: 50px;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            color: var(--text-color);
            text-decoration: underline;
          }

          &:hover {
            &::after {
              color: var(--text-color-hover);
            }
          }
        }

        &::before {
          content: var(--table-title);
          font-weight: 700;
          position: absolute;
          left: 0;
          top: 0;
          width: 600px;
          margin-left: 50px;
          margin-right: 100px;
        }
      }
    }
  }

  &__list {
    padding: 50px 0;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.titles-list {
  .important__subtitle {
    padding: 0;
  }

  &__item {
    margin-bottom: 50px;
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #121212;

    &:hover {
      color: #008ad1;

      span {
        --titles-list-link-span-color: #008ad1;
        &::before,
        &::after {
          background: var(--titles-list-link-span-color);
        }
      }
    }

    &_active {
      span {
        transform-origin: center center;
        transform: rotate(-180deg);
      }
    }

    .important__subtitle {
      margin-right: 30px;
    }

    span {
      width: 24px;
      height: 1px;
      display: block;
      position: relative;
      transition: all 0.3s ease-in-out;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 14px;
        height: 1px;
        background: #121212;
        transform-origin: center center;
        transition: all 0.3s ease-in-out;
      }

      &::before {
        transform: rotate(45deg);
        left: 0;
      }

      &::after {
        transform: rotate(-45deg);
        left: 10px;
      }
    }
  }

  &__data {
    height: 0;
    overflow: hidden;

    > :first-child {
      margin-top: 50px;
    }
  }
}

.important-table-list {
  display: block;
  border-bottom: 0;

  thead {
    display: none;
  }

  tbody {
    tr {
      display: flex;
      flex-direction: column;
      background-color: transparent !important;
    }

    td {
      padding: 0 50px 20px 50px;

      &:first-child {
        padding: 0;
        padding-bottom: 20px;
      }
    }
  }

  &__link {
    color: #121212;
    text-decoration: underline;

    &:hover {
      color: #008ad1;
    }
  }

  &__row {
    height: 46px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    td {
      padding: 0 50px;
    }
  }
}

.test {
  overflow: hidden;

  p {
    height: 0;
  }
}

@media (max-width: 1792.99px) {
  .important {
    &__table_list {
      td {
        padding-left: 600px;
        &:before {
          width: 500px;
        }
      }
    }
  }
}

@media (max-width: 1459.99px) {
  .important {
    &__table_list {
      td {
        padding-left: 550px;
        &:before {
          width: 450px;
        }
      }
    }
  }
}

@media (max-width: 1319.99px) {
  .important {
    &__table_list {
      td {
        padding-left: 0;
        &:before {
          position: relative;
          display: block;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 999.99px) {
  .important {
    &__text {
      width: 100%;
    }

    &__doc {
      width: 100%;
    }

    &__main-title {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 639.99px) {
  .important {
    &__table_list {
      td {
        &:first-child {
          margin-top: 67px;

          &::after {
            top: -67px;
            left: 0;
          }
        }
      }
    }
  }
}
