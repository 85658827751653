.first-screen {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    overflow: hidden;

    img {
      position: absolute;
      max-width: none;
      height: 100vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.hero {
  position: relative;
  margin-top: 190px;

  &__title {
    margin-bottom: 5.6vh;
  }

  &__text {
    margin-bottom: 5.6vh;
  }

  &__cta {
    margin-right: 6.5%;
  }
}

.important-info {
  //margin-top: auto;
  background: var(--bg-light);
  padding: 50px 0;

  &__short-desc {
    margin-bottom: 50px;
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      span {
        margin-left: 35px;
      }
    }

    span {
      font-size: 1.5rem;
      margin-left: 25px;
      transition: all 0.3s ease-in-out;
    }
  }
}

.articles {
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__tab {
    display: block;
    margin-right: 100px;
    color: var(--text-color-inactive);

    h2 {
      line-height: 100%;
    }

    &_active {
      color: var(--text-color);
    }

    &:hover {
      color: var(--text-color);
    }
  }

  &__all-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-text {
      text-transform: lowercase;
      margin-right: 25px;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }

    &-arrow {
      color: var(--text-color);
      font-size: 1.5rem;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }

    &:hover {
      .articles__all-link-text {
        color: var(--text-color-hover);
      }

      .articles__all-link-arrow {
        color: var(--text-color-hover);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &__cards {
    overflow: hidden;
  }
}

.main-important-info {
  &__text,
  &__table {
    margin-bottom: 50px;
  }

  &__text:last-child,
  &__table:last-child {
    margin-bottom: 0;
  }

  &__table-title {
    margin-bottom: 25px;
  }

  &__img {
    width: 100%;
  }
}

.direction-card {
  margin-bottom: 200px;

  &__title {
    padding-left: 130px;
    position: relative;
    line-height: 100%;
    margin-bottom: 50px;
  }

  &__code {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    line-height: 100%;
    font-weight: 300;
  }

  &__text {
    margin-bottom: 10px;

    span:first-child {
      font-weight: 700;
    }

    a {
      color: var(--link-color);

      &:hover {
        color: var(--link-color-hover);
      }
    }
  }

  &__info {
    margin-top: 50px;
    margin-bottom: 100px;
    color: var(--text-color);
  }

  &__admission-btn {
    margin-bottom: 100px;
  }

  &__description {
    font-size: 1rem;
    line-height: 140%;
    color: var(--text-color);

    h3 {
      margin-bottom: 50px;
    }

    ul {
      li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 25px;

        &::before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 5px;
          height: 5px;
          background: var(--text-color);
          border-radius: 50%;
        }
      }
    }

    p {
      margin-bottom: 25px;

      &:first-child {
        margin-top: 25px;
      }
    }
  }
}

.direction-info {
  display: flex;
  justify-content: flex-start;

  &__paid-places,
  &__cost {
    margin-left: 75px;
  }

  &__group {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__main-text {
    margin-bottom: 20px;
  }
}

.subdirections {
  background: var(--bg-light);
  margin-bottom: 0;
  padding: 100px 0;

  &__text {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-top: 50px;
  }
}

@media (max-width: 1792.99px) {
  .hero {
    margin-top: 150px;

    &__title {
      margin-bottom: 5vh;
    }

    &__text {
      margin-bottom: 5vh;
    }

    &__cta {
      margin-right: 6.5%;
    }
  }

  .important-info {
    padding: 40px 0;
  }

  .direction-card {
    &__title {
      padding-left: 90px;
    }
  }
}

@media (max-width: 1459.99px) {
  .direction-info {
    &__paid-places,
    &__cost {
      margin-left: 50px;
    }
  }
}

@media (max-width: 1319.99px) {
  .first-screen {
    display: block;
    height: auto;
    &__bg {
      position: relative;
      width: 100%;
      height: 420px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .hero {
    margin-top: 65px;

    &__title,
    &__text {
      margin-bottom: 40px;
    }
  }

  .important-info {
    margin-top: 40px;
  }

  .main-iportant-info {
    &__img {
      display: none;
    }
  }

  .direction {
    &__img {
      display: none;
    }
  }

  .direction-card {
    margin-bottom: 150px;

    &__info {
      margin-bottom: 50px;
    }

    &__admission-btn {
      margin-bottom: 50px;
    }

    &__description {
      width: 615px;
    }
  }

  .subdirections {
    &__text {
      width: 670px;
    }
  }
}

@media (max-width: 999.99px) {
  .first-screen {
    &__bg {
      height: 250px;
    }
  }

  .hero {
    margin-top: 40px;
  }

  .important-info {
    &__short-desc {
      margin-bottom: 30px;
    }
  }

  .main-important-info {
    &__text {
      margin-bottom: 50px;
    }

    &__table {
      margin-bottom: 50px;
    }
  }

  .direction-card {
    padding-top: 37px;
    margin-bottom: 100px;

    &__title {
      padding-left: 0;
    }

    &__code {
      bottom: calc(100% + 15px);
    }

    &__description {
      width: 100%;
      h3 {
        margin-bottom: 30px;
      }
    }
  }

  .subdirections {
    padding: 50px 0;

    &__text {
      width: 100%;
    }

    &__btn {
      margin-top: 30px;
    }
  }
}

@media (max-width: 639.99px) {
  .hero {
    &__cta {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .important-info {
    &__link {
      span {
        margin-left: 35px;
      }
    }
  }

  .articles {
    &__all-link-text {
      display: none;
    }
  }

  .direction-card {
    &__title {
      line-height: 120%;
      margin-bottom: 30px;
    }
  }

  .direction-info {
    flex-wrap: wrap;
    &__paid-places,
    &__cost {
      margin-left: 0px;
    }

    &__paid-places {
      margin-left: 30px;
    }

    &__cost {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
}
