.cookies-block {
  position: fixed;
  bottom: 60px;
  left: 50%;
  max-width: 940px;
  width: 100%;
  transform: translateX(-50%);
  background-color: var(--bg-dark);
  padding: 15px 25px;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;

  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__svg {
    width: 18px;
    display: inline-block;
    margin: 0 8px;
  }

  &__text {
    margin-bottom: 0;
    color: var(--text-color-light);

    a {
      color: var(--text-color-light);
      text-decoration: underline;

      &:hover {
        color: var(--text-color-hover);
      }
    }
  }

  &__ok {
    margin-left: 50px;
    color: var(--text-color-light);
    padding: 12px 23px;
    text-transform: uppercase;
    border: 1px solid var(--text-color-light);

    &:hover {
      color: var(--text-color-hover);
      border-color: var(--text-color-hover);
    }
  }
}

@media (max-width: 999.99px) {

}
