.not-found-page {
  &__svg {
    width: 100%;
    margin-top: 30px;
    padding: 0 60px;
  }

  &__title {
    margin-top: 130px;
    padding-bottom: 30px;
  }

  &__text {
    margin-bottom: 50px;
  }
}

@media (max-width: 1792.99px) {
  .not-found-page {
    .container__right {
      margin-left: 10px;
      width: 700px;
    }
  }
}

@media (max-width: 1319.99px) {
  .not-found-page {
    &__title {
      margin-top: 0;
    }

    .container {
      &__left {
        width: 600px;
        margin: 0 auto;
        margin-top: -60px;
      }

      &__right {
        margin: 0 auto;
        width: 700px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 999.99px) {
  .not-found-page {
    .container {
      &__left {
        width: 100%;
        margin-top: -20px;
      }

      &__right {
        width: 100%;
      }
    }
  }
}

@media (max-width: 639.99px) {
  .not-found-page {
    &__svg {
      padding: 0;
    }
  }
}
