.priem-page {
  padding-bottom: 50px;

  &__section {
    padding: 50px 0;
  }

  &__section-title {
    margin-bottom: 50px;
  }

  &__title {
    color: var(--text-color-light);
    width: 790px;
    margin-bottom: 50px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
  }
}

.priem-head {
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 50px;
  background-color: var(--bg-primary);
  position: relative;

  &__svg {
    position: absolute;
    top: 10%;
    right: 12%;
  }

  &__group {
    display: flex;
    justify-content: space-between;
    width: 790px;
  }

  &__col {
    width: 50%;
  }
}

.priem-schedule {
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--text-color-light);
    margin-bottom: 20px;
  }

  &__text {
    color: var(--text-color-light);
    margin-bottom: 20px;
  }
}

.priem-rules-link {
  color: var(--text-color-light);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;

  span {
    margin-left: 15px;
    font-size: 1.5rem;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    span {
      margin-left: 25px;
    }
  }
}

.priem-outer {
  margin-top: 7px;

  &__item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: var(--text-color-light);
    font-weight: 500;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--text-color-light);
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      opacity: 1;
    }
  }
}

.priem-nav {
  margin-top: 50px;
  width: 420px;
  padding-left: 50px;

  &__item {
    margin-bottom: 30px;
  }

  &__link {
    font-weight: 500;
    color: var(--link-color);
    display: inline;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid var(--link-color);
    }

    &_active {
      border-bottom: 1px solid var(--link-color);
    }
  }
}

.priem-content {
  width: 1320px;
}

.priem-places {
  &__text {
    margin-bottom: 20px;

    strong {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  &__cards {
    width: 100%;
  }
}

.places-card {
  display: flex;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--bg-primary);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  &:last-child {
    margin-bottom: 0;
  }

  &__code {
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__left {
    max-width: 318px;
    width: 100%;
    margin-right: 50px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  &__paid,
  &__free {
    h4 {
      margin-bottom: 10px;
    }

    table {
      max-width: 100%;
      border-bottom: 1px solid #C4C4C4;
      border-collapse: collapse;

      td {
        padding: 10px 10px 7px 10px;
        vertical-align: top;
        text-align: left;
      }

      thead {
        tr {
          &:first-child {
            background-color: #F1F8FB;

            td {
              font-size: 1rem;
            }
          }

          &:nth-child(2) {
            td {
              font-size: 0.875rem;
            }
          }
        }
      }

      tbody {
        td {
          font-size: 1.125rem;
          font-weight: 700;
        }
      }
    }
  }
}

.priem-score {
  display: flex;
  gap: 30px;

  &__col {
    width: 420px;

    &:last-child {
      margin-top: 86px;
    }
  }

  &__subtitle {
    margin-bottom: 15px;
  }
}

.priem-exam {
  width: 870px;

  &__subtitle {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  &__dropdown-list {
    margin-top: 50px;
  }

  &__text {
    margin-bottom: 20px;
  }
}

.priem-docs {
  width: 870px;

  &__subtitle {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
  }

  &__card {
    width: 420px;
  }
}

.priem-orders {
  width: 870px;

  &__text {
    margin-bottom: 20px;
  }
}

.priem-other-docs {
  width: 870px;

  &__text {
    margin-bottom: 20px;
  }
}

.priem-info {
  width: 870px;

  &__subtitle {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  &__text {
    margin-bottom: 20px;
  }
}

.priem-question {
  width: 870px;

  &__subtitle {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  &__text {
    margin-bottom: 20px;
  }
}

.dropdown-list+h4 {
  margin-top: 25px;
}

@media (max-width: 1792.99px) {
  .priem-content {
    width: 1000px;
  }

  .priem-score {
    flex-wrap: wrap;

    &__col {
      &:last-child {
        margin-left: 450px;
        margin-top: 0;
      }
    }
  }

  .places-card {
    flex-wrap: wrap;

    &__left {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    &__right {
      width: 100%;
    }
  }
}

@media (max-width: 1459.99px) {
  .priem-head {
    margin-bottom: 50px;

    &__svg {
      right: 5%;
    }
  }

  .priem-nav {
    width: 410px;
    padding-left: 30px;
  }
}

@media (max-width: 1319.99px) {
  .priem-head {
    padding: 50px 30px;

    &__svg {
      width: 350px;
      height: 350px;
      right: 1%;

      object {
        width: 100%;
      }
    }
  }

  .priem-page {
    &__group {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .priem-nav {
    width: 100%;
    margin-top: 0;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    gap: 30px;

    &__item {
      width: 300px;
      margin-bottom: 0;
    }
  }

  .priem-content {
    width: 100%;
  }
}

@media (max-width: 999.99px) {
  .priem-head {
    padding: 50px 15px;

    &__svg {
      display: none;
    }

    &__group {
      width: 100%;
    }

    &__col {
      &:first-child {
        width: calc(50% - 10px);
      }
    }
  }

  .priem-nav {
    gap: 20px;
  }

  .priem-places {
    &__cards {
      gap: 20px;
    }
  }

  .places-card {
    padding: 10px;

    &__count {
      margin-bottom: 10px;
    }
  }

  .priem-score {
    gap: 20px;

    &__col {
      width: 300px;

      &:last-child {
        margin-left: 320px;
      }
    }

    .table td {
      padding: 15px 10px;
    }
  }

  .priem-exam {
    width: 100%;
  }

  .priem-docs {
    width: 100%;
  }

  .priem-orders {
    width: 100%;
  }

  .priem-other-docs {
    width: 100%;
  }

  .priem-info {
    width: 100%;
  }

  .priem-question {
    width: 100%;
  }
}

@media (max-width: 639.99px) {
  .priem-page {
    &__title {
      width: 100%;
      margin-bottom: 30px;
    }

    .dropdown-list {
      &__link {
        width: 100%;
        justify-content: space-between;

        h4 {
          width: 256px;
        }

        .small-angle {
          margin-left: 0;
        }
      }
    }
  }

  .priem-head {
    &__group {
      flex-wrap: wrap;
    }

    &__col {
      &:first-child {
        width: 100%;
        margin-bottom: 50px;
      }

      width: 100%;
    }
  }

  .priem-score {
    &__col {
      &:last-child {
        margin-left: 0;
      }
    }
  }

  .places-card {

    &__free,
    &__paid {
      overflow-x: auto;
    }
  }
}